.feed-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-width: 200px;
  background-color: #F8F8FC;
  margin-top: 7rem;
}

.button {
  padding: 0px;
}

@media screen and (min-width: 600px) {
  .feed-wrapper {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .feed-wrapper {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .feed-wrapper {
    width: 50%;
    margin: 0 auto;
  }
}
