.onboarding-card-container {
  padding: 20px 40px 40px 40px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.previous-arrow-container {
  padding-top: 30px;
  padding-left: 30px;
}

.previous-arrow {
  border-left: solid 2px #4a4a4a;
  border-bottom: solid 2px #4a4a4a;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
}

.onboarding-card-content {
  padding: 40px 0 0 0;
  flex-grow: 1;
}

.onboarding-button-wrapper {
  align-self: flex-end;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .onboarding-card-layout-wrapper {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .onboarding-card-layout-wrapper {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .onboarding-card-layout-wrapper {
    width: 50%;
    margin: 0 auto;
  }
}
