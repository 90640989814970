.profile-img-section {
  text-align: center;
  background-color: #f4f4f4;
}

.profile-img-container {
  position: relative;
  display: inline-block;
}

.profile-img {
  margin-top: 20px;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.add-profile-pic {
  position: absolute;
  top: 4.2rem;
  left: 2.8rem;
}

.upload-pic-input {
  display: none;
  /* position: absolute;
  left: -2000; */
}

.char-count {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #6e6e6e;
}

.change-password-link {
  color: var(--primary-emphasis);
  text-decoration: none;
}

.edit-account-footer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-emphasis);
  align-items: flex-start;
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 6px;
}

.edit-account-footer > * {
  padding: 10px 4px;
}

.delete-account-button {
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-emphasis);
}

.mobilize-logo-container-edit-account {
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
}

.mobilize-logo-edit-account {
  width: 54px;
}

@media screen and (min-width: 600px) {
  .edit-account-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .edit-account-container {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .edit-account-container {
    width: 50%;
    margin: 0 auto;
  }
}
