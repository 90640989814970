.top-comment-wrapper {
  margin-bottom: 1.75rem;
}

.number-of-comments {
  width: 100%;
  margin-bottom: 1rem;
  margin-left: 25px;
  margin-top: 5px;
  margin-left: 25px;
  margin-bottom: 0px;
}