.official-section {
  padding: 0 20px;
}

.official-section-content-wrapper {
  padding: 20px 0;
  border-bottom: 0.5px solid #979797;
}

.official-section-content-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.official-section:last-child .official-section-content-wrapper {
  border-bottom: none;
}

.official-section-title {
  font-size: 1.3rem;
  margin: 0 0 1em 0;
  text-align: center;
  color: black;
}

/* .official-section-title h2 {
  color: black;
} */

.official-section-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.default-padding:first-child{
  padding-top: 0em;
}
