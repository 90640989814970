.news-wrapper {
  font-family: AppDSans-Regular sans-serif;
}

.not-comment {
  border-bottom: solid 1px #cccccc;
  margin: 10px 0;
  padding: 10px 0;
}

.agree-disagree-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  /* font-size: 10px;
  font-weight: 500;
  color: #2e2d2d;
  padding: 4px 0;
  text-align: right; */
  font-size: .75em;
}

.agree-disagree-container img {
  margin: 0 .35em;
}

.agree-container {
  display: flex;
}

.user-interaction-container {
  display: flex;
  margin-top: 1.6em;
  align-items: center;
  margin-bottom: 8px;
}

.thumbUp-img {
  width: 8px;
}

.thumb-img-story-hairline {
  width: 10px;
}

.thumbDown-img {
  padding-top: 2px;
  width: 7px;
}

.num {
  padding-right: 2px;
  padding-left: 2px;
}

.news-img-container {
  width: auto;
  height: 125px;
  margin-top: .45em;
}

.news-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news-title {
  width: 88%;
}

.option-btn {
  padding-right: 14px;
}

/* h5 {
  margin-top: 0.8rem;
  margin-bottom: 0;
} */

.news-title-and-link {
  font-size: 18px;
  color: #333333;
}

.news-content {
  font-size: 12px;
  color: #333333;
}

.news-source-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
  margin-top: 1.75em;
}

/* .news-source-date > * {
  /* padding-right: 8px; */
/* } */ 

.news-source-container {
  display: flex;
  align-items: center;
  /* flex: 1; */
}

.source-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.news-source {
  font-weight: 900;
  margin-left: 8px;
  color: #000000;
  margin-right: 4px;
  font-size: 12px;
}

.publish-date {
  color: #9b9b9b;
  font-size: 12px;
  margin-left: 4px;
}

.view-comment-container {
  font-size: 12px;
  color: #6d6d6d;
}

.display-comment {
  font-size: 12px;
  color: #000000;
}
