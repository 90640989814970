::-webkit-input-placeholder {
  color: #333333;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  width: 95%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
}
::-moz-placeholder {
  color: #333333;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  width: 95%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #333333;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  width: 95%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
}
::placeholder {
  color: #333333;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  width: 95%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
}

.edit-text-input {
  width: 95%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  font-weight: 300;
}

/* .edit-text-input::placeholder {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-family: AppDSans-Light, sans-serif;
  font-weight: 300;
} */

.prefix-username {
  display: inline-block;
}
