.badge-container {
  position: relative;
}

.badge-svg {
  width: 1.85rem;
  height: 1.85rem;
  border-radius: 50%;
  position: absolute;
  left: 3.5rem;
  top: -2rem;
}
