.bio-section-container {
  padding: 10px 7px;
  background-color: white;
  margin: 10px 3px;
}

.bio-content-label {
  font-weight: bold;
  text-align: left;
}

.bio-content-content {
  font-size: 12px;
  padding: 4px 0px;
}
