.interest-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: scroll;
  height: 310px;
  padding-bottom: 20px;
  border: 10px;
  column-gap: 15px;
  overflow-x: hidden;
  padding-right: 20px;


}

.interest-container.short {
  height: 350px;
  padding-bottom: 20px;
  padding: 0 10px;
  overflow: scroll;
}

.interest-button.active {
  border: solid 1px #4e3eb1;
  background-color: #E9E5FF;
  color: #4e3eb1;
}

.interest-button {
  border: solid 1px #979797;
  border-radius: 3px;
  margin: 8px;
  color: #6d6d6d;
  display: block;
  appearance: none;
  font-family: AppDSans, sans-serif;
  font-size: 18px;
  background: transparent;
  text-align: center;
  padding: 18px 0;
  outline: none;
}

.interest-button.short {
  margin: 12px;
}

.add-key-words-text {
  color: #6d6d6d;
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
}

.customized-interest-container {
  display: inline;
  flex-wrap: wrap;
  border-bottom: solid 2px #4e3eb1;
}

.pills-list {
  list-style: none;
  flex-wrap: wrap;
  display: inline;
}

.pills-input {
  outline-color: none;
  width: 120px;
  height: 34px;
}

.pills-input:focus {
  outline: none;
}
