.hair-line {
  display: flex;
  justify-content: flex-end;
  font-size: .75em;
}

.hair-line img {
  margin-left: 0;
  margin-right: 0;
  margin: 0 .35em;
}

.hair-line span {
  display: flex;
  margin: 0 .5em;
}