@font-face {
  font-family: "AppDSans";
  src: url("../public//fonts/appdfont/eot/AppDSans-Regular.eot");
  src: url("../public/fonts/appdfont/eot/AppDSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/appdfont/woff2/AppDSans-Regular.woff2") format("woff2"),
    url("../public/fonts/appdfont/woff/AppDSans-Regular.woff") format("woff"),
    url("../public/fonts/appdfont/ttf/AppDSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AppDSans";
  src: url("../public/fonts/appdfont/eot/AppDSans-Bold.eot");
  src: url("../public/fonts/appdfont/eot/AppDSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/appdfont/woff2/AppDSans-Bold.woff2") format("woff2"),
    url("../public/fonts/appdfont/woff/AppDSans-Bold.woff") format("woff"),
    url("../public/fonts/appdfont/ttf/AppDSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "AppDSans";
  src: url("../public/fonts/appdfont/eot/AppDSans-Light.eot");
  src: url("../public/fonts/appdfont/eot/AppDSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/appdfont/woff2/AppDSans-Light.woff2") format("woff2"),
    url("../public/fonts/appdfont/woff/AppDSans-Light.woff") format("woff"),
    url("../public/fonts/appdfont/ttf/AppDSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Monument";
  src: url("../public/fonts/monument/MonumentExtended-Regular.otf");
  src: url("../public/fonts/monument/MonumentExtended-Regular.otf?#iefix") format("embedded-opentype"),
    url("../public/fonts/monument/MonumentExtended-Regular.otf") format("otf"),
}

:root {
  --primary-emphasis: #4943ab;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "AppDSans", sans-serif;
}

h1 {
  font-size: 2em;
}


.sign-up {
  background-image: url("../public/img/loginBackground.png");
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 2rem;
}

html {
  font-size: 1em;
}

a {
  color: black;
}

h5 {
  margin: 0.8rem 0;
}

p {
  margin: 0.5rem 0;
}

button {
  border: none;
  /* color: white;
  background-color: #4A90E2;
  font-size: 1em;
  text-align: center;
  padding: 1em;
  width: 100%; */

}

button:focus {
  outline: none;
}

a:focus {
  outline: none;
}

textarea,
input {
  border: none;
  padding: 8px;
}

:focus {
  outline: 1px solid var(--primary-emphasis);
}

.feedSearch {
  width: 100%;
}

.ballot-link {
  border-left: thick solid #ff0000;
  border-color: black;
  padding-left: 0.5rem;
  vertical-align: center;
}

.ballot-icon {
  width: 26px;
  padding-right: 0.5rem;
  vertical-align: middle;
}

.chat-icon {
  width: 26px;
}

#modal-root {
  position: sticky;
  width: 50%;
  margin: 0 auto;
}