@media screen and (min-width: 600px) {
  .signup-form-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .signup-form-container {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .signup-form-container {
    width: 50%;
    margin: 0 auto;
  }
}

/* Google sign in button according to google branding guidelines*/
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  margin: 10px auto;
  outline: none;
  padding: 0 16px;
  text-align: center;
  transition: background-color .218s, box-shadow .218s;
  width: fit-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 8px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  flex-grow: 1;
  font-weight: 500;
}

.gsi-material-button:not(:disabled):hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gsi-material-button:not(:disabled):active {
  background-color: #e0e0e0;
}

.gsi-material-button:disabled {
  background-color: #ffffff61;
  cursor: not-allowed;
  opacity: 0.6;
}

.gsi-material-button .gsi-material-button-state {
  transition: opacity .218s;
  opacity: 0;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  opacity: 0.1;
}
