.section-wrapper {
  padding-bottom: 3rem;
  background-color: #F8F8FC;
  margin-top: 9rem;
  
}

.view-politician-link {
  font-size: 12px;
  border: solid black 2px;
  color: black;
  padding: 4px 12px;
  text-align: center;
  font-weight: 900;
  width: 13rem;
}
