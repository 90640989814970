.previous-and-edit-container {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  padding: 10.5px 25px;
  background-color: white;
  z-index: 3;
}

.previous-and-edit-container img {
  margin: 0;
}

.header-button {
  font-size: 12px;
  width: 85px;
  border-radius: 2px;
  color: white;
  border: solid 1px var(--primary-emphasis);
  padding: 4px;
  text-align: center;
  background-color: var(--primary-emphasis);
}
