.media-icon-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 510px;
  overflow: scroll;
  padding: 6px;
  text-align: center;
}
.media-icon-container.short {
  height: 205px;
}

.media-container {
  padding: 2px 0 4px 0;
  border-bottom: solid 2px transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-container {
  height: 67px;
  border: solid 4px white;
  border-radius: 50%;
  text-align: center;
}

.img-container.active {
  height: 67px;
  border: solid 4px rgb(127, 85, 224);
  border-radius: 50%;
  text-align: center;

}

.media-icon {
  display: inline-block;
  width: 60px;
  margin: auto 0;
}

.news-source {
  /* padding-top: 4px; */
  font-size: 12px;
  font-weight: 900;
  border: solid 1px white;
  text-align: center;
}

.news-source.active {
  border-bottom: solid 1px rgb(107, 54, 230);
}
