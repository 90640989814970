.authenticate-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 350px;
    height: 250px;
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid #000;
    box-shadow: 1px 1px;
    padding: 20px;    
}

.modal-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color:rgba(146, 187, 238, 0.331);
}

#auth-close {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
}

.authenticate-modal form, .authenticate-modal label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px;
}

.authenticate-modal input {
    text-align: center;
}

.hide-overflow {
    overflow: hidden;
}

.confirm-delete {
    display: flex;
}

.confirm-delete button {
    width: 95.25px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 1em;
}

.confirm-action {
    background-color: #da728780;
}

.cancel-action {
    background-color: #92bbee80;
}