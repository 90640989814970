.sub-title {
  color: #6d6d6d;
  font-size: 16px;
}

.under-input-text {
  color: #6d6d6d;
  font-size: 12px;
}

.onboarding-svg {
  width: 75%;
  margin: auto;
  display: block;
}
