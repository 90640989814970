.nav {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 5.9rem;
  z-index: 2;
  background-color: white;
}

.div-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  border-bottom: solid 1px #979797;
  height: 2.625rem;
  align-items: center;
  position: fixed; /* Keep it fixed */
  top: 6.85rem; /* Adjust this value to be just below the main nav */
  left: 0; /* Align it to the left */
  right: 0; /* Align it to the right */
  z-index: 10; /* Ensure it overlays other content */
  background-color: white; /* Set background color to avoid transparency */
  
}

.div-sub-header {
  grid-template-columns: 1fr 1fr;
  padding: 0 6rem;
}

.subTabs {
  font-size: 17px;
  margin: 0;
}

.anchor {
  text-decoration: none;
  color: #9b9b9b;
}

.anchor-active {
  color: #000000;
}


