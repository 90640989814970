.primary-button {
  background-color: #4A90E2; 
  color: white; 
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer; 
  font-size: 16px; 
  transition: background-color 0.3s ease;
  width: 100%;
}

.primary-button:hover {
  background-color: #0056b3; 
}

.primary-button:disabled {
  background-color: #cccccc; 
  cursor: not-allowed; 
}

