.error-message {
  font-size: 14px;
  margin: 8px;
}

.error-message-color-red {
  color: #cc0000;
}

.error-message-color-white {
  color: #ffffff;
}
