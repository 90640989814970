.view-comments-option {
  display: block;
  margin: .25rem 0 1.5rem 0;
  font-size: .75rem;
}

.grandchild-wrap {
  position: relative;
  left: -24px;
}

textarea {
  width: 100%;
  display: flex;
}

form input {
  float: right;
}

form {
  margin-bottom: 1.5em;
}

.top-wrap {
  /* margin-top: .75rem; */
  display: flex;  
}

.child-wrap {
  margin-left: .7em;
}

.feed-profile-img-container {
  display: flex;
}

.feed-comment-wrapper {
  border-bottom: solid 1px #cccccc;
  margin: 10px 0;
  padding: 10px 0;
  font-family: AppDSans-Regular sans-serif;
}

.feed-comment-profile-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.comment-wrap {
  width: 100%;
}

.comment-wrap-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: .35rem;
}

.comment-wrap-info-inner {
  display: flex;
}

.comment-wrap-info-inner p:first-child {
  font-weight: bold;
}

.comment-wrap-info-inner p:nth-child(2) {
  opacity: .75;
}

.comment-wrap-info-inner p {
  margin: 0 .45em;
  font-size: .7em;
}

.feed-comment-reply {
  display: flex;
  gap: .25em;
  font-size: .90em;
  font-weight: 600;
}

.feed-comment-reply span {
  font-size: .75rem;
}

.greyed-out {
  opacity: .5;
}

.comment-content {
  margin: .5em .45em;
  margin-right: 0em;
  background-color: #FEEAE9;
}

.view-grandChildren {
  font-size: .7em;
  margin-left: .85em;
}

.feed-supported {
  height: 1.5em;
  margin: 0 .45em;
  padding: 0 .7em;
  display: flex;
  font-size: .65rem;
  align-items: center;
  gap: .5em;
  border-radius: 20px;
  background-color: #DAD9ED;
}

.feed-opposed {
  background-color: #FF5A71;
}

.politician-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.politician-name-image-wrap {
  display: flex;
  justify-content: center;
}

.politician-name-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.politician-name-desc span:first-child {
  font-weight: bold;
}

.politician-info button {
  display: flex;
  align-items: center;
  color: #4943AB;
  font-weight: bold;
  border: 1px solid #4943AB;
  padding: 1em;
  height: 2.5em;
  width: 7em;
  font-size: .75em;
  border-radius: 2px;
}