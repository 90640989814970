.comment-window-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.comment-window-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(153, 150, 150, 0.5);
}

.comment-window-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comment-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #f3f3f3;
  gap: 1em;
}

.comment-button-container > * {
  font-size: 14px;
  padding: 8px 0;
  color: #b3afaf;
  font-weight: bold;
}

.thumbUp-img-big {
  padding-right: 2px;
  width: 13px;
}

.thumbDown-img-big {
  padding-right: 2px;
  width: 11px;
}

.input-container {
  padding: 15px 20px;
}

.comment-input {
  width: 100%;
  height: 30px;
}

.publish-button {
  background-color: var(--primary-emphasis);
  color: white;
  width: 60px;
  height: 30px;
}

.support,
.oppose {
  color: rgb(73,67,171);
  background-color: rgb(218,217,238);
}

.undo-support {
  color: #eee8e8;
}

button img {
  display: inline;
}

.comment-button-container button {
  width: 100%;
}