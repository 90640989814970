.tab-bar-item {
  display: block;
  flex-grow: 1;
  display: grid;
  text-align: center;
  font-size: 12px;
  color: #989898;
  font-weight: bold;
}

.tab-bar-item:focus {
  outline: none;
}

.tab-bar-item.active {
  border-bottom: solid 2px var(--primary-emphasis);
  color: var(--primary-emphasis);
}
