.person-wrapper {
  margin-bottom: 0.9rem;
  padding: .6rem 0rem;
  display: flex;
  align-items: center;
}

.content-and-btn {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  padding-left: 0.25rem;
  flex-grow: 1;
}

.content-wrapper {
  padding: 0 1.25rem;
}

.content-header {
  font-size: 1.1rem;
  font-family: SharpSansNoOne-Bold, sans-serif;
  margin: 0;
  padding: 0;
  margin-bottom: .25em;
}