.restart-signup-link {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-emphasis);
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  text-decoration: underline;
}

.onboarding-svg {
  width: 75%;
  margin: auto;
  display: block;
}
