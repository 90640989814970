.edit-text-input-container {
  display: flex;
  flex-direction: column;
}

.label-container {
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 6px;
}

.description {
  font-weight: normal;
}

.content {
  padding-top: 4px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 4px;
}
