.component-container {
  padding: 20px;
}

.search-result-display {
  padding: 20px 0;
}

.no-result-container {
  padding: 50% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.email-invite-link {
  background-color: var(--primary-emphasis);
  color: #ffffff;
  height: 1.5rem;
  width: 7rem;
  border: none;
  line-height: 26px;
}

@media screen and (min-width: 600px) {
  .component-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .component-container {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .component-container {
    width: 50%;
    margin: 0 auto;
  }
}
