.search-bar {
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  width: 100%;
  height: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
}

.search-bar:focus {
  outline-color: var(--primary-emphasis);
}
