.header-text {
  font-family: Monument, sans-serif;
  font-weight: 400;
  margin-top: 35%;
}

.text-header-light {
  color: #f6f6f6;
  font-size: 3.5rem;
}


.text-header-dark {
  color: #6d6d6d;
  margin-top: 40px;
}
