.person-wrapper {
  margin-bottom: 0.9rem;
  padding: .6rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-and-btn {

}

.content-wrapper {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-header {
  font-size: 1.2rem;
  font-family: STIX_Two_Text;
  margin: 0;
  padding: 0;
  padding-bottom: .25rem;
}

.person-profile-container {
  display: flex;
}

.view-profile-btn {
  /* padding: 0 1.2rem; */
  
}