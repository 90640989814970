.bill-profile-wrapper {
  background-color: #f4f4f4;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bill-title-container {
  position: sticky;
  top: 45px;
  font-size: 21px;
  padding-top: 14px;
  font-weight: bold;
  background-color: white;
  line-height: 1.48;
}

.bill-id {
  font-size: 14px;
  padding: 10px 5px;
  color: #6d6d6d;
  text-align: center;
  border-radius: 3px;
}

.bill-title {
  padding: 0 15px;
  text-align: center;
  margin-bottom: 15px;
}

.bill-navigation {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  background-color: white;
  box-shadow: 0 2px 3px rgb(0 0 0 /15%);
}

.bill-navigation div {
  font-weight: 700;
  width: 100%;
  padding: 10px;
  color: gray;
  text-align: center;
}

#selected-bill-nav {
  color: #4943AB;
  border-bottom: 2.5px solid #4943AB;
}

.agree,
.disagree {
  color: rgb(73,67,171);
  background-color: rgb(218,217,238);
}

@media screen and (min-width: 600px) {
  .bill-profile-wrapper {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .bill-profile-wrapper {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .bill-profile-wrapper {
    width: 50%;
    margin: 0 auto;
  }
}

.bill-info-button {
  background-color: #4943AB;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.bill-info-button-wrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  z-index: 10;
  width: 100%;
}