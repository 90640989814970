.bill-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 2rem 0;
  font-family: system-ui, -apple-system, sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

/* Header Styles */
.bill-content metadata,
.bill-content dublinCore {
  display: none; /* Hide metadata section */
}

.bill-content form {
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.bill-content distribution-code,
.bill-content congress,
.bill-content session {
  display: block;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.bill-content legis-num {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1rem 0;
  color: #333;
}

.bill-content current-chamber {
  display: block;
  font-weight: 500;
  margin: 1rem 0;
  color: #444;
}

/* Action Styles */
.bill-content action {
  display: block;
  margin: 1.5rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 4px;
}

.bill-content action-date {
  display: block;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.bill-content action-desc {
  display: block;
  line-height: 1.5;
}

/* Resolution Body Styles */
.bill-content resolution-body {
  margin-top: 2rem;
}

.bill-content whereas {
  display: block;
  margin: 1rem 0;
  line-height: 1.6;
  text-indent: 2rem;
}

.bill-content whereas::before {
  /* content: "Whereas, "; */
  font-weight: bold;
}

.bill-content legis-type {
  display: block;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 2rem 0;
  color: #333;
  text-transform: uppercase;
}

/* Add margin to the official-title to separate it from the resolution header */
.bill-content official-title {
  display: block;
  margin-top: 1.5rem;
  line-height: 1.6;
}

.bill-content section {
  margin: 1.5rem 0;
}

.bill-content section text {
  display: block;
  line-height: 1.6;
}

/* Sponsor and Cosponsor Styles */
.bill-content sponsor,
.bill-content cosponsor {
  color: #2b5797;
  text-decoration: none;
}

.bill-content sponsor:hover,
.bill-content cosponsor:hover {
  text-decoration: underline;
}

/* Committee Styles */
.bill-content committee-name {
  font-style: italic;
  color: #666;
}

.bill-content resolution-body {
  margin-top: 2rem;
  position: relative;
}

/* Style the em tag when it exists */
.bill-content em {
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 2rem 0 1rem;
  color: #333;
}
