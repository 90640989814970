.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 2;
  width: 100%;
}

.user-description {
  font-size: 12px;
  color: #6d6d6d;
  padding: 6px 20px;
}

.support-oppose-container {
  display: flex;
  justify-content: space-between;
  width: 18em;
}

.support-oppose-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  padding: .5em;
  font-weight: 500;
  color: #4943AB;
  border: 2px solid #4943AB;
}

button img {
  margin: 0;
  margin-right: 8px;
}

.profile-children {

}

.selected {
  color: white;
  font-weight: 500;
  background-color: #4943AB;
}

.edit-link {
  display: block;
  font-size: 12px;
  color: var(--primary-emphasis);
}

.profile-img {
  margin-top: 20px;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.fullname {
  font-size: 21px;
  font-weight: bold;
  color: #4a4a4a;
}

@media screen and (min-width: 600px) {
  .profile-container .profile-children {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .profile-container .profile-children{
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .profile-container .profile-children {
    width: 50%;
    margin: 0 auto;
  }
}
