.forgot-password-text {
  color: #ffffff;
  font-size: 14px;
  font-family: sans-serif;
  opacity: 0.7;
}

.forgot-password-link {
  display: block;
  align-self: center;
}

.mobilize-logo-container {
  text-align: center;
}

.mobilize-logo {
  height: 46px;
}

@media screen and (min-width: 600px) {
  .signin-form-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .signin-form-container {
    width: 60%;
    margin: 0 auto;
    height: 100vh;
    overflow:hidden
  }
}

@media screen and (min-width: 1500px) {
  .signin-form-container {
    width: 50%;
    margin: 0 auto;
  }
}

.PrimaryButton .gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 32px;
  height: 39px;
  margin: 10px 0px;
  outline: none;
  padding: 10px 56px; 
  text-align: center;
  transition: background-color .218s, box-shadow .218s;
  max-width: none;
  width: 100%;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 48px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px;
}

.gsi-material-button .gsi-material-button-contents {
  flex-grow: 1;
  font-weight: 500;
  text-align: center;
}

.gsi-material-button:not(:disabled):hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gsi-material-button:not(:disabled):active {
  background-color: #e0e0e0;
}