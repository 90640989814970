#proposition-list {
    padding: 0;
    overflow: scroll;
    background-color: #F8F8FC;
    /* margin-top: 0; */
    padding-top: 0;
    margin-top: 6rem;
    padding-bottom: 45px;
};


h2 {
    color: black;
}