.option-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.option-modal-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(153, 150, 150, 0.5);
}

.option-modal-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 24px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-container > * {
  font-size: 16px;
  padding: 8px 0;
}

.cancel-button-container {
  width: 100%;
  border-top: solid 1px #e2e2e6;
  padding: 10px 0 18px 0;
}

.cancel-button {
  font-size: 16px;
  display: block;
  margin: 0 auto;
}
