.bio-section-container {
  padding: 7px 3px;
  background-color: white;
  margin: 7px 3px;
}

.bio-content-label {
  font-weight: 700;
  font-size: 21px;
  text-align: left;
  font-family: 'STIX Two Text', serif;
}

.bio-content-content {
  font-size: 16px;
  padding: 4px 0px;
  font-family: 'Archivo', sans-serif;
}
