.section-container {
  background: url("../../../../../public/img/home.svg");
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
}

.name-email-wrapper {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 64px 0 64px;
}

.mobilize-logo-container {
  text-align: center;
}

.mobilize-logo {
  height: 46px;
}

h2 {
  color: white;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 26px;
  font-size: 18px;
  background-color: rgba(216, 216, 216, 0.21);
}

.footer-text {
  color: white;
  opacity: 0.7;
}

.login-link {
  color: white;
  padding: 4px;
}
.svg-container {
  display: flex;
  justify-content: flex-end; /* Aligns the SVGs to the right */
  margin-top: 50px;
  /* margin-bottom: 10px; Adds space between the SVGs and the text input */
}

.svg-container > * {
  margin-left: 10px; /* Adds space between each SVG */
}

.PrimaryButton .gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 32px;
  height: 39px;
  margin: 10px 0px;
  outline: none;
  padding: 10px 56px; 
  text-align: center;
  transition: background-color .218s, box-shadow .218s;
  max-width: none;
  width: 100%;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 48px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px;
}

.gsi-material-button .gsi-material-button-contents {
  flex-grow: 1;
  font-weight: 500;
  text-align: center;
}

.gsi-material-button:not(:disabled):hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gsi-material-button:not(:disabled):active {
  background-color: #e0e0e0;
}