.text-input-container {
  height: 60px;
  margin: 12px 0;
}

.text-input {
  appearance: none;
  border-radius: 7px;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.8rem;
  padding: 0.45rem;
  outline: none;
  border: none;
  margin-top: 0.5rem;
}

.text-input-light {
  color: #ffffff;
  background-color: rgba(216, 216, 216, 0.21);
  font-size: 0.9rem;
}

.text-input-light::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.text-input-dark {
  border: solid 1px #9b9b9b;
}
