.header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 2px 2px;
  align-items: center;
}

#tab-container-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #9b9b9b;
}

.tab-container {
  /* width: 243px;
  font-size: 2vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  
}

#people-search-icon {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.ballot-icon {
  width: 20px;
  height: 16px;
  padding-right: 0;
}

.light-ballot-icon {
  width: 16px;
  height: 16px;
  padding-right: 0;
  margin-right: 2px;
}

.ballot-link-container {
  padding-left: .3em;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ballot-divider {
  height: 1.1em;
  background-color: grey;
  width: 1px;
}
@media only screen and (max-width: 340px) {
  
}

h3 {
  text-align: center;
  line-height: 2.5em;
}

.profile-img-NavBar {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
}

.profile-img-container {
  padding-left: 2rem;
}

.nav-bar-item {
  color: #9b9b9b;
  font-size: 14px;
  padding: 10px 20px;
  transition: color 0.3s ease;
}
.nav-bar-item.active {
  color: #000000;
}

.header-wrapper {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 50%);
}

@media screen and (min-width: 600px) {
  .header-wrapper {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .header-wrapper {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .header-wrapper {
    width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .nav-bar-item {
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}
