.div-card {
  padding: 10px 20px;
  /* border: solid 1px #979797;
  border-radius: 7px; */
  font-weight: bold;
  margin: 10px 10px;
  /* text-align: center; */
  font-family: AppDSans, Arial, sans-serf;
}

.div-card > h2 {
  color: #333;
}

.default-margin:first-child {
  margin-top: 5em;
}

.title {
  font-family: STIX_Two_Text;
  color: #333;
  font-size: 16px;
  margin: 2px;
  padding-bottom: 12px;
  font-weight: 600;
}
.name {
  font-size: 12px;
  margin: 2px;
}
.status {
  font-size: 12px;
  margin: 2px;
}
.link {
  font-size: 12px;
  color: var(--primary-emphasis);
  font-weight: 500;
  margin: 2px;
}

.view-proposition-link {
  border: none;
}
