/*
  Titillium Web for font family?
*/

.support-container {
  min-width: 235px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.support-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 25px;
  padding: 1em;
  font-weight: 700;
  background-color: #C8C8C82B;
  /* color:#4943AB; */
  /* border: 2px solid #4943AB; */
  border-radius: 3px;
  text-align: center
}

.button-support {
  background-color: rgba(73, 67, 171, .15);
}

#transparent {
  background-color: transparent;
}

#light-theme > button {
  /* border: 1px solid #4943AB; */
  padding: .55em;
  font-size: .70em;
  margin-left: 5px;
}

#light-theme {
  min-width: 0px;
}

.comment-icon {
  width: 14px;
}

.support-icon {
  width: 18px;
}

button span {
  font-size: .7rem;
}