.count-wrapper {
  color: #9b9b9b;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.number-of-followers {
  color: #000000;
  font-weight: bold;
}
