@media screen and (min-width: 600px) {
  .ballot-container {
    width: 70%;
    /* margin: 0 auto; */
    margin-top: 7rem;
  }
}

@media screen and (min-width: 1200px) {
  .ballot-container {
    width: 60%;
    /* margin: 0 auto; */
    margin-top: 7rem;
  }
}

@media screen and (min-width: 1500px) {
  .ballot-container {
    width: 50%;
    /* margin: 0 auto; */
    margin-top: 7rem;
  }
}

.section-wrapper {
  padding-bottom: 3rem;
  background-color: #F8F8FC;
  margin-top: 9rem;
  
}
