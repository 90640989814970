/* ballot tab */
.btn-base {
  background-color: #ffffff;
  font-size: 0.75rem;
  color: var(--primary-emphasis);
  height: 2.3rem;
  width: 10rem;
}

.btn-primary {
  border: solid 1px var(--primary-emphasis);
  border-radius: 2px;
}

.btn-active {
  background-color: var(--primary-emphasis);
  color: #ffffff;
}

/* people tab */
.btn-following {
  background-color: var(--primary-emphasis);
  color: #ffffff;
  height: 1.5rem;
  width: 7rem;
  border: none;
}

.btn-follow {
  background-color: var(--primary-emphasis);
  color: #ffffff;
  height: 1.5rem;
  width: 7rem;
  border: none;
}
