.interest-list-item {
  border-radius: 10px;
  background-color: #E9E5FF;
  text-align: left;
  margin: 4px 6px;
  padding: 6px 4px;
  font-size: 1em;
  display: inline-block;
  margin-right: 10px;
  display: inline;
}
