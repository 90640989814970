.comments-container {
  padding: 1em;
  width: 100%;
  height: 100%;
  background-color: white;
}

.comment-fullname {
  font-weight: bold;
  font-size: 0.8rem;
}

.comment-content {
  border-radius: 3px;
  background-color: #eceaea;
  /* margin: 0 0 0 20px; */
  padding: 4px 10px;
  font-size: 0.8rem;
}

.is-feed-comment {
  background-color: #FEE5E5;
}

.reply-button {
  margin: 2px 0 2px 20px;
  padding: 2px 10px;
  font-weight: bold;
  font-size: 10px;
  background-color: #ffffff;
}

.reply-container {
  padding: 4px 0 4px 30px;
}

.reply-icon {
  width: 10px;
}

.child-wrap {
  margin-left: .7em;
}

.grandchild-wrap-2 {
  margin-left: 1.4em;
}

.profile-feed-img-container {
  margin: 0;
}


/* FROM COMMENTS.BAK */
.comments-page-container .feed-comment-wrapper {
  padding: 1.2em;
}

.previous-and-edit-container-comments {
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  padding: 10.5px 25px;
  position: relative;
}

.go-back-arrow {
  position: absolute;
  left: 20px;
}

.name-and-time {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-time {
  margin-left: 10px;
  font-size: 10px;
}

.comments-container {
  padding: 30px;
}

.toggle-show-all-replies {
  padding: 4px 25px;
}
